import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LessonsGridComponent } from './lessons-grid.component';
import { LessonItemModule } from '../lesson-item/lesson-item.module';

@NgModule({
  declarations: [LessonsGridComponent],
  imports: [CommonModule, LessonItemModule],
  exports: [LessonsGridComponent],
})
export class LessonsGridModule {}
