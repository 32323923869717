import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { Lesson } from '../../../dataset/Lesson';
import chunk from 'lodash/chunk';
import { User } from '../../../dataset/User';

@Component({
  selector: 'lessons-grid',
  templateUrl: 'lessons-grid.component.html',
  styleUrls: ['lessons-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LessonsGridComponent {
  private _columnCount: number;
  @Input() set columnCount(value: number) {
    this._columnCount = value || 4;
    this.generateColumns();
  }

  get columnCount(): number {
    return this._columnCount;
  }

  @Input() isBought: boolean;
  @Input() hideCalendarButton = false;

  private _lessons: Lesson[];
  @Input() set lessons(value: Lesson[]) {
    if (value) {
      this._lessons = value;
      this.generateColumns();
    }
  }

  get lessons(): Lesson[] {
    return this._lessons;
  }

  @Input() user: User | null;

  @Output() subscribe: EventEmitter<void> = new EventEmitter<void>();
  @Output() addToSchedule: EventEmitter<Lesson> = new EventEmitter();
  @Output() lessonClick: EventEmitter<Lesson> = new EventEmitter();

  @HostBinding('class.layout-row')
  @HostBinding('class.grid-5')
  classes = true;

  columns: Lesson[][] = [];

  private generateColumns(): void {
    if (this.lessons && this.columnCount) {
      const lastColumn = this.lessons.length % this.columnCount;
      const perColumn =
        (this.lessons.length - lastColumn) / this.columnCount + (lastColumn !== 0 ? 1 : 0);
      this.columns = chunk(this.lessons, perColumn);
    }
  }

  subscribeHandler(): void {
    this.subscribe.emit();
  }

  addToScheduleHandler(lesson: Lesson): void {
    this.addToSchedule.emit(lesson);
  }

  click(lesson: Lesson): void {
    if (this.isBought || lesson.free || !this.user) {
      this.lessonClick.emit(lesson);
      return;
    }
  }
}
