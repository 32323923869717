import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { Lesson } from '../../../dataset/Lesson';
import { User } from '../../../dataset/User';
import { LessonUtils } from '../../../context/lessons-context/lesson-utils';

@Component({
  selector: 'lesson-item',
  templateUrl: 'lesson-item.component.html',
  styleUrls: ['lesson-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LessonItemComponent {
  @HostBinding('class.layout-row')
  layout = true;

  private _lesson: Lesson;
  @Input() set lesson(value: Lesson) {
    if (value) {
      this._lesson = LessonUtils.perform(value);
    }
  }

  get lesson(): Lesson {
    return this._lesson;
  }

  @Input() hideCalendarButton = false;
  @Input() isBought: boolean;
  @Input() fromAdd: boolean;
  @Input() user: User | null;

  @Output() subscribe: EventEmitter<Lesson> = new EventEmitter();
  @Output() lessonClick: EventEmitter<Lesson> = new EventEmitter();
  @Output() addToSchedule: EventEmitter<Lesson> = new EventEmitter();

  addToScheduleHandler(lesson: Lesson): void {
    this.addToSchedule.emit(lesson);
  }

  subscribeHandler(): void {
    this.subscribe.emit();
  }

  @HostListener('click')
  lessonClickHandler(): void {
    this.lessonClick.emit(this.lesson);
  }

  get img(): string | undefined {
    return this.lesson?.images.s3_square_240;
  }
}
