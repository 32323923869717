<div
  class="flex"
  *ngFor="let column of columns">
  <lesson-item
    *ngFor="let lesson of column"
    [lesson]="lesson"
    [isBought]="isBought"
    [fromAdd]="true"
    [user]="user"
    (lessonClick)="click($event)"
    (subscribe)="subscribeHandler()"
    (addToSchedule)="addToScheduleHandler($event)"
    [hideCalendarButton]="hideCalendarButton" />
</div>
