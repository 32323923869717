import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { Value } from '../../../../dataset/Value';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export type Segment = Value<number> | Value<string> | Value<number | null>;

@Component({
  selector: 'segment-control',
  templateUrl: 'segment-control.component.html',
  styleUrls: ['segment-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SegmentControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SegmentControlComponent implements ControlValueAccessor {
  @Input() label?: string;
  @Input() useImage = false;
  @Input() segments: Segment[] = [];
  @Input() selectedSegment?: Segment;

  @Output() selectedSegmentChange: EventEmitter<Segment> = new EventEmitter();

  onChange: (obj: unknown) => void;
  onTouch: () => void;

  selectSegment(segment: Segment): void {
    this.selectedSegment = segment;
    this.selectedSegmentChange.emit(this.selectedSegment);
    if (this.onChange) {
      this.onChange(segment.value);
    }
  }

  writeValue(obj: number | string): void {
    this.selectedSegment = this.segments.find(s => obj === s.value);
    this.selectedSegmentChange.emit(this.selectedSegment);
    if (this.onChange) {
      this.onChange(this.selectedSegment ? this.selectedSegment.value : null);
    }
  }

  registerOnChange(fn: (obj: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  @HostListener('click') click(): void {
    if (this.onTouch) {
      this.onTouch();
    }
  }
}
