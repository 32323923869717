<div
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="20px">
  <p
    class="st-p1 segments-label st-color-black"
    *ngIf="label">
    {{ label | translate }}
  </p>

  <div
    fxLayout="row"
    class="segments">
    <button
      fxFlex="1 1"
      class="segments-item"
      fxLayout="row"
      fxLayoutAlign="center"
      *ngFor="let segment of segments"
      [class.active]="selectedSegment === segment"
      (click)="selectSegment(segment)">
      <ng-container *ngIf="useImage; else labelTemplate">
        <span [innerHtml]="selectedSegment === segment ? segment.activeImg : segment.img"></span>
      </ng-container>

      <ng-template #labelTemplate>
        <span>{{ segment.label ?? '' | translate }}</span>
      </ng-template>
    </button>
  </div>
</div>
