import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LessonItemComponent } from './lesson-item.component';
import { IconModule } from '../ui/icon/icon.module';
import { NgPipesModule } from 'ngx-pipes';
import { TranslateModule } from '@ngx-translate/core';
import { InterpolateDirective } from '../../../core/directives/interpolation/interpolate.directive';

@NgModule({
  declarations: [LessonItemComponent],
  imports: [CommonModule, IconModule, NgPipesModule, TranslateModule, InterpolateDirective],
  exports: [LessonItemComponent],
})
export class LessonItemModule {}
