<div class="flex auto">
  <img
    *ngIf="img"
    [ngClass]="{ w67: fromAdd }"
    alt=""
    [src]="img" />
</div>

<div class="info flex">
  <h4 [ngClass]="{ 'h4-14': fromAdd }">
    {{ lesson.name | translate }}
  </h4>

  <h6 [ngClass]="{ 'fs-125': fromAdd }">
    {{ lesson.description | translate | lines | initial: 0 }}
  </h6>

  <div
    class="meta"
    [ngClass]="{ 'm-text': fromAdd }">
    <span class="duration">
      <icon name="time"></icon>
      <span
        [stInterpolate]="'[minutes] min' | translate"
        [params]="{ minutes: lesson.minutes }"></span>
    </span>

    <span class="level">
      <icon name="complexity"></icon>

      <span *ngIf="lesson.is_all_levels; else complexityTemplate">
        {{ 'all levels' | translate }}
      </span>

      <ng-template #complexityTemplate>
        <span>{{ lesson.complexity | translate }}</span>
      </ng-template>
    </span>

    <span class="points">
      <icon name="star"></icon>
      <span
        [stInterpolate]="'[points] points' | translate"
        [params]="{ points: lesson.duration }"></span>
    </span>
  </div>
</div>

<div
  class="lesson-action"
  *ngIf="!isBought && !lesson.free">
  <icon name="lock"></icon>
</div>

<span
  class="lesson-action active"
  *ngIf="(isBought || lesson.free) && !hideCalendarButton"
  role="presentation"
  (click)="$event.stopPropagation(); addToScheduleHandler(lesson)">
  <icon name="date-add"></icon>
</span>

<div
  class="cover"
  *ngIf="!isBought && !lesson.free && user">
  <a
    role="presentation"
    class="subscribe"
    (click)="subscribeHandler()">
    {{ 'Subscribe' | translate }}
  </a>
</div>
